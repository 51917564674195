import React from "react"
import Img from "gatsby-image"
import RichTextRenderer from "../../RichTextRenderer"

// Components
import FeaturedWork from "./featuredWork"

const Details = ({
  data: {
    sectionOneTitle,
    sectionOneDescription,
    sectionOneFeaturedWork,
    sectionTwoTitle,
    sectionTwoDescription,
    sectionTwoFeaturedWork,
  },
}) => {
  return (
    <div className="max-w-6xl px-4 mx-auto graphicDesignPage md:px-8 xl:px-0">
      <div className="pb-4 xl:justify-cente">
        <h2 className="text-2xl font-bold text-white uppercase xl:text-4xl siteFont">
          {sectionOneTitle}
        </h2>
        <div className="py-2 Gray md:text-lg">
          {RichTextRenderer(sectionOneDescription.json)}
        </div>

        <FeaturedWork items={sectionOneFeaturedWork} />

        <div className="mt-6">
          <h2 className="text-2xl font-bold text-white uppercase xl:text-4xl siteFont">
            {sectionTwoTitle}
          </h2>
          <div className="my-3 Gray lg:text-lg">
            {RichTextRenderer(sectionTwoDescription.json)}
          </div>
          <FeaturedWork items={sectionTwoFeaturedWork} video />
        </div>
      </div>
    </div>
  )
}

export default Details
