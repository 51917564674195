import React, { useContext } from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import Banner from "../../components/Services/GraphicDesign/banner"
import DetailsSection from "../../components/Services/GraphicDesign/detailsSection"
import Slider from "../../components/slider"
import ContactUs from "../../components/contactUs"
import Estimator from "../../components/PricingCalculator/estimator"

// Context API data
import { AppContext } from "../../store/AppContext"

const GraphicDesign = ({ data }) => {
  const globalData = useContext(AppContext)
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerDescription,
    bannerImage,
    sectionOneTitle,
    sectionOneDescription,
    sectionOneFeaturedWork,
    sectionTwoTitle,
    sectionTwoDescription,
    sectionTwoFeaturedWork,
  } = data.website

  const bannerData = {
    bannerTitle,
    bannerSubtitle,
    bannerDescription,
    bannerImage,
  }

  const detailData = {
    sectionOneTitle,
    sectionOneDescription,
    sectionOneFeaturedWork,
    sectionTwoTitle,
    sectionTwoDescription,
    sectionTwoFeaturedWork,
  }

  return (
    <Layout>
      <SEO
        title={ogTitle || "Website Development"}
        description={ogDescription}
      />
      <Banner data={bannerData} customNav={globalData?.state?.customNav} />
      <DetailsSection data={detailData} />
      <Estimator
        singleService
        service="Animation"
        title="Animation Price Estimator"
      />
      <Slider blueBackground />
      <ContactUs />
    </Layout>
  )
}

export const query = graphql`
  query GraphicDesignPageQuery {
    website: contentfulServicesGraphicDesign {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerDescription {
        json
      }
      bannerImage {
        title
        file {
          url
        }
      }
      sectionOneTitle
      sectionOneDescription {
        json
      }
      sectionOneFeaturedWork {
        id
        slug
        image {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      sectionTwoTitle
      sectionTwoDescription {
        json
      }
      sectionTwoFeaturedWork {
        id
        videoUrl
        videoImage {
          title
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`

export default GraphicDesign
