import React from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

// Assets
import { PlayBtn } from "./icons"

const ShowcaseTile = ({ data, selectVideo, video }) => {
  return video ? (
    <div
      key={data.id}
      className="w-full h-full full showcaseTile"
      onClick={() => selectVideo && selectVideo(data)}
    >
      <div className="w-full">
        <Img
          fluid={data.videoImage.fluid}
          alt={data.videoImage.title}
          className="w-full rounded-md"
        />
      </div>

      <div className="w-12 play-btn lg:w-16">
        <PlayBtn className="w-full h-full" />
      </div>
    </div>
  ) : (
    <Link
      to={`/case-study/${data.slug}`}
      key={data.id}
      className="w-full h-full full showcaseTile"
    >
      <div className="w-full h-full">
        <Img
          fluid={data?.image?.fluid}
          alt={data?.image?.title}
          className="object-contain w-full h-full rounded-md"
        />
      </div>
    </Link>
  )
}

export default ShowcaseTile
