import React, { useState } from "react"
import ReactPlayer from "react-player"

// Components
import Modal from "../../modal"
import ShowcaseTile from "../../showcaseTile"

// Assets
import { PlayBtn, CloseIcon } from "../../icons"

const FeaturedWork = ({ items, video }) => {
  const [modal, setModal] = useState(false)
  const [selectedVid, setSelectedVid] = useState(null)

  const selectVideo = item => {
    setModal(true)
    setSelectedVid(item)
  }

  return video ? (
    <div className="mb-6">
      {modal && (
        <Modal close={() => setModal(false)}>
          <div className="vid-player">
            <ReactPlayer
              playing={true}
              url={selectedVid.videoUrl}
              width="100%"
              height="100%"
            />
          </div>
          <CloseIcon
            onClick={() => setModal(false)}
            className="absolute block w-6 cursor-pointer md:hidden top-1/50 md:top-1/20 right-1/30"
          />
        </Modal>
      )}

      <h2 className="mb-6 text-3xl text-white capitalize siteFont">
        Featured works:
      </h2>
      <div className="grid justify-between grid-cols-1 mb-12 md:grid-cols-3 gap-y-6 md:gap-x-8">
        {items.map(item => {
          return <ShowcaseTile data={item} selectVideo={selectVideo} video />
        })}
      </div>
    </div>
  ) : (
    <div className="mb-6">
      <h2 className="mb-6 text-3xl text-white capitalize siteFont">
        Featured works:
      </h2>
      <div className="grid justify-between grid-cols-1 md:grid-cols-3 gap-y-6 md:gap-x-6">
        {items.map(item => {
          return <ShowcaseTile data={item} />
        })}
      </div>
    </div>
  )
}

export default FeaturedWork
